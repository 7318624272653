// import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'
import TemperaturaView from '@/components/Layout/Menu/Temperatura/TemperaturaView.vue'

export default {
  name: 'SondaView',
  components: {
    TemperaturaView
  },
  props: {
    sonda: null,
    sitio: null,
    camara: null
  },
  data: function () {
    return {
      items: []
    }
  },
  watch: {
  },
  mounted () {
    const date = new Date()
    const fechaActualHasta = `${
      date.getFullYear().toString().padStart(4, '0')}-${
        (date.getMonth() + 1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')} ${
            date.getHours().toString()}:${
              date.getMinutes().toString()}:${
                date.getSeconds().toString()}`
    const datePast = new Date()
    let miliseconds = date.getMilliseconds()
    const milisecondsDay = 24 * 60 * 60 * 1000
    miliseconds = miliseconds - milisecondsDay
    datePast.setMilliseconds(miliseconds)
    const fechaActualDesde = `${
      datePast.getFullYear().toString().padStart(4, '0')}-${
        (datePast.getMonth() + 1).toString().padStart(2, '0')}-${
          datePast.getDate().toString().padStart(2, '0')} ${
            datePast.getHours().toString()}:${
              datePast.getMinutes().toString()}:${
                datePast.getSeconds().toString()}`
    console.log(fechaActualDesde)
    console.log(fechaActualHasta)
    // this.getSitios().then((res) => {
    //   console.log(res)
    //   this.items = []
    //   this.items = this.sitios.data
    //   this.totalRows = this.items.length
    // })
  },
  methods: {
    // ...mapActions('Sitio', [
    //   'getSitios'
    // ])
  },
  computed: {
    // ...mapState('Sitio', ['sitios'])
  }
}
