<script>
// Importing Line class from the vue-chartjs wrapper
import { Line } from 'vue-chartjs'

// Exporting this so it can be used in other components
export default {
  extends: Line,
  props: {
    render: null,
    Labels: null,
    Datasets: null
  },
  data () {
    return {
      datacollection: {
        // Data to be represented on x-axis
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          // {
          //   label: 'Data One',
          //   backgroundColor: '#f87979',
          //   pointBackgroundColor: 'white',
          //   borderWidth: 1,
          //   pointBorderColor: '#249EBF',
          // Data to be represented on y-axis
          //   data: [45, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100]
          // },
          // {
          // fill: false,
          // define que tan rectas son las lineas que juntan cada punto
          // tension: 0,
          // define el interlineado
          //   borderDash: [5, 5, 5, 5, 5, 5],
          //   label: 'Data Two',
          //   pointBackgroundColor: 'red',
          //   borderWidth: 1,
          //   borderColor: '#FF0000',
          //   pointBorderColor: '#FF0000',
          //   data: [10, 40, 30, 40, 50, 60, 70, 80, 90, 100, 90, 100]
          // }
        ]
      },
      // Chart.js options that controls the appearance of the chart
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    // renderChart function renders the chart with the datacollection and options object.
    // this.chart.update()
    console.log('se crear barra')
    this.renderChart(this.datacollection, this.options)
    this.$emit('emitRender', true)
  },
  watch: {
    render: {
      handler: function (val, oldVal) {
        console.log('render')
        this.renderChart(this.datacollection, this.options)
      }
    },
    Labels: {
      handler: function (val, oldVal) {
        console.log('render labels')
        this.datacollection.labels = this.Labels
        this.datacollection.datasets = this.Datasets
        this.renderChart(this.datacollection, this.options)
      }
    }
  }
}
</script>
