import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EliminarAlertaTemperaturaView',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: {}
    }
  },
  watch: {
  },
  mounted () {
    // console.log('alertas')
    // this.getAlertas().then((res) => {
    //   console.log(res)
    // })
    this.dataJSON = JSON.parse(this.data)
  },
  methods: {
    ...mapActions('Alerta', [
      'getAlertas', 'updateAlerta', 'deleteAlerta'
    ]),
    eliminar () {
      console.log(this.dataJSON.id)
      console.log('eliminar alerta')
      this.deleteAlerta(this.dataJSON.id).then((res) => {
        console.log('delete alerta con exito')
        this.$emit('deleteAlerta', this.alerta)
      })
    }
  },
  computed: {
    ...mapState('Alerta', ['alertas']),
    ...mapState('Alerta', ['alerta'])
  }
}
