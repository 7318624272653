import { mapActions, mapState } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'KpiTemperaturaView',
  components: {
    Carousel,
    Slide,
    show: false
  },
  props: {
    data: null
  },
  data: function () {
    return {
    }
  },
  watch: {
  },
  mounted () {
    // this.show = true
    // this.getSKpiTemperaturas().then((res) => {
    //   this.show = false
    //   console.log(res)
    // })
  },
  methods: {
    ...mapActions('KPI', [
      'getSKpiTemperaturas'
    ]),
    onShown () {
      // Focus the cancel button when the overlay is showing
      this.$refs.cancel.focus()
    },
    onHidden () {
      // Focus the show button when the overlay is removed
      this.$refs.show.focus()
    }
  },
  computed: {
    ...mapState('KPI', ['temperaturas'])
  }
}
