import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EditarAlertaTemperaturaView',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: {},
      selected: null,
      options: [
        { text: 'Activar', value: true },
        { text: 'Desactivar', value: false }
      ]
    }
  },
  watch: {
  },
  mounted () {
    // console.log('alertas')
    // this.getAlertas().then((res) => {
    //   console.log(res)
    // })
    this.dataJSON = JSON.parse(this.data)
  },
  methods: {
    ...mapActions('Alerta', [
      'getAlertas', 'updateAlerta'
    ]),
    editar () {
      console.log(this.dataJSON)
      console.log('update alerta')
      this.updateAlerta(this.dataJSON).then((res) => {
        console.log('update alerta con exito')
        this.$emit('updateAlerta', this.alerta)
      })
    }
  },
  computed: {
    ...mapState('Alerta', ['alertas']),
    ...mapState('Alerta', ['alerta'])
  }
}
