import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import MenuView from '../views/MenuView.vue'
import GraficarCamarasView from '../views/GraficarCamarasView.vue'
import PanelControlView from '../views/PanelControlView.vue'
import AlertaTemperaturaView from '../views/AlertaTemperaturaView.vue'
import ContactoView from '../views/ContactoView.vue'
import ConfiguracionView from '../views/ConfiguracionView.vue'
import LoginView from '../views/LoginView.vue'
// import SidebarView from '../views/SidebarView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/GraficarCamaras',
    name: 'GraficarCamarasView',
    component: GraficarCamarasView
  },
  {
    path: '/PanelControl',
    name: 'PanelControlView',
    component: PanelControlView
  },
  {
    path: '/AlertaTemperatura',
    name: 'AlertaTemperaturaView',
    component: AlertaTemperaturaView
  },
  {
    path: '/Contacto',
    name: 'ContactoView',
    component: ContactoView
  },
  {
    path: '/Configuracion',
    name: 'ConfiguracionView',
    component: ConfiguracionView
  },
  // {
  //   path: '/menu',
  //   name: 'SidebarView',
  //   component: SidebarView
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
