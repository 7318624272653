import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'
import CrearAlertaTemperaturaView from '@/components/Layout/Menu/Modulos/Alertas/Alerta_temperatura/Crear_alerta_temperatura/CrearAlertaTemperaturaView.vue'
import EditarAlertaTemperaturaView from '@/components/Layout/Menu/Modulos/Alertas/Alerta_temperatura/Editar_alerta_temperatura/EditarAlertaTemperaturaView.vue'
import EliminarAlertaTemperaturaView from '@/components/Layout/Menu/Modulos/Alertas/Alerta_temperatura/Eliminar_alerta_temperatura/EliminarAlertaTemperaturaView.vue'

export default {
  name: 'AlertaTemperaturaView',
  components: {
    CrearAlertaTemperaturaView,
    EditarAlertaTemperaturaView,
    EliminarAlertaTemperaturaView
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        // { key: 'id', label: 'ID', sortable: true, sortDirection: 'desc' },
        // { key: 'id_sonda', label: 'ID Sonda', sortable: true, class: 'text-center' },
        { key: 'nombre_sitio', label: 'Cliente', sortable: true, class: 'text-center' },
        { key: 'nombre_camara', label: 'Camara', sortable: true, class: 'text-center' },
        { key: 'nombre_sonda', label: 'Sonda', sortable: true, class: 'text-center' },
        { key: 'creador', label: 'Creador' },
        { key: 'temperatura_minima', label: 'Temperatura Minima' },
        { key: 'temperatura_maxima', label: 'Temperatura Maxima' },
        { key: 'correo', label: 'Correo' },
        { key: 'fecha_creacion', label: 'Fecha Creacion' },
        { key: 'activa', label: 'Activa' },
        { key: 'editar', label: 'Editar' },
        { key: 'eliminar', label: 'Eliminar' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      crearAlertaModal: {
        id: 'crear-alerta-modal',
        title: '',
        content: ''
      },
      eliminarAlertaModal: {
        id: 'eliminar-alerta-modal',
        title: '',
        content: ''
      },
      updateAlerta: {},
      insertAlerta: {},
      deleteAlerta: {}
    }
  },
  watch: {
    updateAlerta: function (val) {
      this.getAlertas().then((res) => {
        // console.log(res)
        this.hideModal()
        this.items = this.alertas.data
        this.totalRows = this.items.length
        const toast = {}
        toast.body = 'Alerta Actualizada Con Exito'
        toast.title = 'Tabla Alertas Actualizada'
        toast.variant = 'success'
        this.makeToast(toast)
      })
    },
    insertAlerta: function (val) {
      this.getAlertas().then((res) => {
        // console.log(res)
        this.hideCrearAlertaModal()
        this.items = this.alertas.data
        this.totalRows = this.items.length
        const toast = {}
        toast.body = 'Alerta Creada Con Exito'
        toast.title = 'Tabla Alertas Actualizada'
        toast.variant = 'success'
        this.makeToast(toast)
      })
    },
    deleteAlerta: function (val) {
      this.getAlertas().then((res) => {
        // console.log(res)
        this.hideEliminarAlertaModal()
        this.items = this.alertas.data
        this.totalRows = this.items.length
        const toast = {}
        toast.body = 'Alerta Eliminada Con Exito'
        toast.title = 'Tabla Alertas Actualizada'
        toast.variant = 'success'
        this.makeToast(toast)
      })
    }
  },
  mounted () {
    console.log('alertas')
    this.getAlertas().then((res) => {
      console.log(res)
      this.items = this.alertas.data
    })
  },
  methods: {
    ...mapActions('Alerta', [
      'getAlertas'
    ]),
    info (item, index, button) {
      this.infoModal.title = 'Alerta'
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    hideModal () {
      this.$refs[this.infoModal.id].hide()
    },
    crearAlerta (item, index, button) {
      this.crearAlertaModal.title = 'Crear Alerta'
      this.crearAlertaModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.crearAlertaModal.id, button)
    },
    resetCrearAlertaModal () {
      this.crearAlertaModal.title = ''
      this.crearAlertaModal.content = ''
    },
    hideCrearAlertaModal () {
      this.$refs[this.crearAlertaModal.id].hide()
    },
    eliminarAlerta (item, index, button) {
      this.eliminarAlertaModal.title = 'Eliminar Alerta'
      this.eliminarAlertaModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.eliminarAlertaModal.id, button)
    },
    resetEliminarAlertaModal () {
      this.eliminarAlertaModal.title = ''
      this.eliminarAlertaModal.content = ''
    },
    hideEliminarAlertaModal () {
      this.$refs[this.eliminarAlertaModal.id].hide()
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    }
  },
  computed: {
    ...mapState('Alerta', ['alertas']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
