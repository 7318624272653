import { render, staticRenderFns } from "@/components/Layout/Menu/Dashboard/SidebarPricipal/SidebarPricipal.html?vue&type=template&id=52ca9582&scoped=true&"
import script from "@/components/Layout/Menu/Dashboard/SidebarPricipal/SidebarPricipal.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/Dashboard/SidebarPricipal/SidebarPricipal.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/Dashboard/SidebarPricipal/SidebarPricipal.css?vue&type=style&index=0&id=52ca9582&prod&scoped=true&lang=css&"
import style1 from "./SidebarView.vue?vue&type=style&index=1&id=52ca9582&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ca9582",
  null
  
)

export default component.exports