import { render, staticRenderFns } from "@/components/Layout/Menu/Temperatura/Temperatura.html?vue&type=template&id=47d5b1cc&scoped=true&"
import script from "@/components/Layout/Menu/Temperatura/Temperatura.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Menu/Temperatura/Temperatura.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Menu/Temperatura/Temperatura.css?vue&type=style&index=0&id=47d5b1cc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d5b1cc",
  null
  
)

export default component.exports