// import { mapActions, mapState } from 'vuex'
import { mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
// import axios from 'axios'
import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'
import BootstrapSidebar from 'vue-bootstrap-sidebar/src/vue-bootstrap-sidebar.vue'

export default {
  name: 'sidebarView',
  components: {
    BootstrapSidebar
  },
  props: {
  },
  data: function () {
    return {
      // token: store.state.token,
      initialShow: false,
      header: '<img src="https://upload.wikimedia.org/wikipedia/commons/9/90/Sodexo_logo.svg" width="130" height="50">',
      links: [
        { name: 'Panel Control', href: { name: 'PanelControlView' }, faIcon: ['fas', 'home'] },
        { name: 'Graficar Camaras', href: { name: 'GraficarCamarasView' }, faIcon: ['fas', 'home'] },
        {
          name: 'Alertas',
          faIcon: ['fas', 'home'],
          children: [
            {
              name: 'Temperatura',
              href: {
                name: 'AlertaTemperaturaView'
              },
              faIcon: ['fas', 'child']
            }
          ]
        },
        { name: 'Configuracion', href: { name: 'ConfiguracionView' }, faIcon: ['fas', 'home'] },
        {
          name: 'Administracion',
          faIcon: ['fas', 'home'],
          children: [
            {
              name: 'Usuarios',
              href: {
                name: ''
              },
              faIcon: ['fas', 'child']
            },
            {
              name: 'Dispositivos',
              href: {
                name: ''
              },
              faIcon: ['fas', 'child']
            }
          ]
        },
        { name: 'Acerca de', href: { name: 'about' }, faIcon: 'users' },
        { name: 'Contacto', href: { name: 'ContactoView' }, faIcon: 'phone' }
      ],
      items: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Manage',
          href: '#'
        },
        {
          text: 'Library',
          active: true
        }
      ]
    }
  },
  watch: {
    token: function (val) {
      console.log('entra en observador token')
      if (this.token === '') {
        this.initialShow = false
        this.header = 'Soporte'
        this.links = [
          { name: 'Acerca de', href: { name: 'about' }, faIcon: 'users' },
          { name: 'Contacto', href: { name: 'ContactoView' }, faIcon: 'phone' },
          { name: 'Inicia sesión', href: { name: 'LoginView' } }
        ]
      } else {
        this.initialShow = true
        this.header = '<img src="https://upload.wikimedia.org/wikipedia/commons/9/90/Sodexo_logo.svg" width="130" height="50">'
        this.links = [
          { name: 'Panel Control', href: { name: 'PanelControlView' }, faIcon: ['fas', 'home'] },
          { name: 'Graficar Camaras', href: { name: 'GraficarCamarasView' }, faIcon: ['fas', 'home'] },
          {
            name: 'Alertas',
            faIcon: ['fas', 'home'],
            children: [
              {
                name: 'Temperatura',
                href: {
                  name: 'AlertaTemperaturaView'
                },
                faIcon: ['fas', 'child']
              }
            ]
          },
          { name: 'Configuracion', href: { name: 'ConfiguracionView' }, faIcon: ['fas', 'home'] },
          {
            name: 'Administracion',
            faIcon: ['fas', 'home'],
            children: [
              {
                name: 'Usuarios',
                href: {
                  name: ''
                },
                faIcon: ['fas', 'child']
              },
              {
                name: 'Dispositivos',
                href: {
                  name: ''
                },
                faIcon: ['fas', 'child']
              }
            ]
          },
          { name: 'Acerca de', href: { name: 'about' }, faIcon: 'users' },
          { name: 'Contacto', href: { name: 'ContactoView' }, faIcon: 'phone' }
        ]
      }
    }
  },
  mounted () {
    if (this.token === '') {
      this.initialShow = false
      this.header = 'Soporte'
      this.links = [
        { name: 'Acerca de', href: { name: 'about' }, faIcon: 'users' },
        { name: 'Contacto', href: { name: 'ContactoView' }, faIcon: 'phone' },
        { name: 'Inicia sesión', href: { name: 'LoginView' } }
      ]
    }
  },
  methods: {
    // ...mapActions('AdministradorFlota', [
    //   'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    // ])
    onSidebarChanged (data) {
      console.log(data)
      if (data) {
        console.log('abre o cierra')
      } else {
        console.log('cierra')
      }
    },
    cerrarSesion () {
      console.log('test')
      // this.initialShow = false
      store.state.token = ''
      window.location.href = '/'
      router.push({ path: '/' })
    }
  },
  computed: {
    ...mapState(['token'])
  }
}
