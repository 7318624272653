import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    temperaturas: []
  },
  mutations: {
    set_accion_temperaturas (state, temperaturas) {
      state.temperaturas = temperaturas
    }
  },
  actions: {
    async getemperaturas ({ commit, dispatch, state }, data) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/sodexo/temperatura/getByTemperaturaAndSonda?id_sonda=${data.id_sonda}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: data.periodos
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getemperaturas')
          // console.log(response.data)
          commit('set_accion_temperaturas', response)
          return true
        } else {
          console.log('por else getemperaturas')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getemperaturas')
        window.location.href = '/'
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
