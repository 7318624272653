<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <SidebarView ></SidebarView>
    <!-- <span><router-view/></span> -->
  </div>
</template>

<script>

import SidebarView from '@/views/SidebarView.vue'
import store from '@/store'

export default {
  components: {
    SidebarView
  },
  data () {
    return {
      token: store.state.token
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/* #sidebar {
  background-color: black;
 }
 .list-group-item {
  background-color: black;
 } */

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
