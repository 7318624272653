// import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'
import SondaView from '@/components/Layout/Menu/Sonda/SondaView.vue'

export default {
  name: 'CamaraView',
  components: {
    SondaView
  },
  props: {
    camaras: null,
    sitio: null
  },
  data: function () {
    return {
      items: [],
      sondas: [],
      selected: null,
      options: []
    }
  },
  watch: {
    selected: function (val) {
      // console.log(this.selected)
      this.sondas = this.itemsAux.sondas
      // if (this.selected === null) {
      //   this.sondas = this.itemsAux.sondas
      // } else {
      //   console.log(this.itemsAux.sondas)
      //   this.sondas = []
      //   this.sondas = this.itemsAux.sondas.filter(filter => filter.nombre === this.selected)
      //   console.log(this.sondas)
      // }
    },
    sondas: function (val) {
      // console.log('sondas')
      if (this.sondas === this.itemsAux.sondas && this.selected !== null) {
        const aux = this.itemsAux.sondas
        this.sondas = aux.filter(filter => filter.nombre === this.selected)
      }
    }
  },
  mounted () {
    this.items = this.camaras
    this.itemsAux = this.items
    this.sondas = this.items.sondas
    this.options.push({ value: null, text: '---Seleccione Sonda---' })
    for (const camara in this.camaras.sondas) {
      this.options.push({ value: this.camaras.sondas[camara].nombre, text: this.camaras.sondas[camara].nombre })
    }
    // this.getSitios().then((res) => {
    //   console.log(res)
    //   this.items = []
    //   this.items = this.sitios.data
    //   this.totalRows = this.items.length
    // })
  },
  methods: {
    // ...mapActions('Sitio', [
    //   'getSitios'
    // ])
  },
  computed: {
    // ...mapState('Sitio', ['sitios'])
  }
}
