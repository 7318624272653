import { mapActions } from 'vuex'
// import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'LoginView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      usuario: '',
      password: ''
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'GetToken'
    ]),
    login () {
      console.log('login')
      // const credenciales = { usernameOrEmail: this.usuario, password: this.password }
      const credenciales = { usernameOrEmail: 'ccampos', password: 'pass' }
      // console.log(credenciales)
      this.GetToken(credenciales).then((res) => {
        console.log(res)
        console.log('cargado GetToken')
        if (!res) {
          this.$bvToast.toast('Usuario o Clave incorrectas', {
            title: 'Fallo Inicio de sesion',
            variant: 'danger',
            solid: true
          })
        }
      })
    }
  },
  computed: {
    // ...mapState('token')
  }
}
