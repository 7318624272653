import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    alertas: [],
    alerta: {}
  },
  mutations: {
    set_accion_alertas (state, alertas) {
      state.alertas = alertas
    },
    set_accion_alerta (state, alerta) {
      state.alerta = alerta
    }
  },
  actions: {
    async getAlertas ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/sodexo/alertas/getAll`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAlertas')
          // console.log(response.data)
          commit('set_accion_alertas', response)
          return true
        } else {
          console.log('por else getAlertas')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAlertas')
        window.location.href = '/'
        router.push({ path: '/' })
        return false
      }
    },
    async updateAlerta ({ commit, dispatch, state }, alerta) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/sodexo/alertas/update`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: alerta
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api updateAlerta')
          // console.log(response.data)
          commit('set_accion_alerta', response)
          return true
        } else {
          console.log('por else updateAlerta')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateAlerta')
        window.location.href = '/'
        router.push({ path: '/' })
        return false
      }
    },
    async insertAlerta ({ commit, dispatch, state }, alerta) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/sodexo/alertas/insert`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: alerta
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api insertAlerta')
          // console.log(response.data)
          commit('set_accion_alerta', response)
          return true
        } else {
          console.log('por else insertAlerta')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch insertAlerta')
        window.location.href = '/'
        router.push({ path: '/' })
        return false
      }
    },
    async deleteAlerta ({ commit, dispatch, state }, idAlerta) {
      try {
        const response = await axios({
          method: 'delete',
          url: `${store.state.host}/api/sodexo/alertas/delete?id=${idAlerta}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api deleteAlerta')
          // console.log(response.data)
          commit('set_accion_alerta', response)
          return true
        } else {
          console.log('por else deleteAlerta')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch deleteAlerta')
        window.location.href = '/'
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
