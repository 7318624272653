import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    sitios: []
  },
  mutations: {
    set_accion_sitios (state, sitios) {
      state.sitios = sitios
    }
  },
  actions: {
    async getSitios ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/sodexo/sitio/getAll`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getSitios')
          // console.log(response.data)
          commit('set_accion_sitios', response)
          return true
        } else {
          console.log('por else getSitios')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getSitios')
        window.location.href = '/'
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
