import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'
import CamaraView from '@/components/Layout/Menu/Camara/CamaraView.vue'

export default {
  name: 'SitioView',
  components: {
    CamaraView
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      itemsAux: [],
      selected: null,
      options: [],
      selectedCamara: null,
      optionsCamara: []
    }
  },
  watch: {
    selected: function (val) {
      this.items = this.itemsAux
      this.optionsCamara = []
      this.optionsCamara.push({ value: null, text: '---Seleccione Camara---' })
      for (const camara in this.selected.camaras) {
        // console.log(this.selected.camaras[camara])
        this.optionsCamara.push({ value: this.selected.camaras[camara].id_camara_frio, text: this.selected.camaras[camara].nombre })
      }
    },
    items: function (val) {
      if (this.items === this.itemsAux && this.selected !== null) {
        const aux = this.itemsAux
        this.items = aux.filter(filter => filter.direccion === this.selected)
      }
    }
  },
  mounted () {
    this.options.push({ value: null, text: '---Seleccione Sitio---' })
    this.optionsCamara.push({ value: null, text: '---Seleccione Camara---' })
    this.getSitios().then((res) => {
      // console.log(res)
      this.items = []
      this.items = this.sitios.data
      this.itemsAux = this.items
      // console.log(this.items)
      for (const sitio in this.items) {
        this.options.push({ value: this.items[sitio], text: this.items[sitio].direccion })
        for (const camara in this.items[sitio].camaras) {
          // console.log(this.items[sitio].camaras[camara].id_camara_frio)
          this.optionsCamara.push({ value: this.items[sitio].camaras[camara].id_camara_frio, text: this.items[sitio].camaras[camara].nombre })
        }
      }
      // this.totalRows = this.items.length
    })
  },
  methods: {
    ...mapActions('Sitio', [
      'getSitios'
    ])
  },
  computed: {
    ...mapState('Sitio', ['sitios'])
  }
}
