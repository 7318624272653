import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'CrearAlertaTemperaturaView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      idSonda: '',
      creador: '',
      temperaturaMinima: 0.0,
      temperaturaMaxima: 0.0,
      correo: '',
      activa: true,
      options: [
        { text: 'Activar', value: true },
        { text: 'Desactivar', value: false }
      ],
      selectedSitio: null,
      optionsSitio: [
        // { value: null, text: 'Seleccionar un Cliente' }
      ],
      selectedCamara: null,
      optionsCamara: [
        // { value: null, text: 'Seleccionar una Camara' }
      ],
      selectedSonda: null,
      optionsSonda: [
        // { value: null, text: 'Seleccionar una sonda' }
      ]
    }
  },
  watch: {
    selectedSitio: function (val) {
      if (this.selectedSitio !== null) {
        // console.log(this.selectedSitio)
        this.optionsCamara = []
        this.optionsCamara.push({ value: null, text: 'Seleccionar una Camara' })
        this.optionsSonda = []
        this.optionsSonda.push({ value: null, text: 'Seleccionar una Sonda' })
        this.selectedSonda = null
        this.selectedSitio.camaras.forEach(camara => {
          this.optionsCamara.push({ value: camara, text: camara.nombre })
        })
      } else {
        this.optionsCamara = []
        this.selectedCamara = null
        this.optionsSonda = []
        this.selectedSonda = null
      }
    },
    selectedCamara: function (val) {
      if (this.selectedCamara !== null) {
        // console.log(this.selectedCamara)
        this.optionsSonda = []
        this.selectedSonda = null
        this.optionsSonda.push({ value: null, text: 'Seleccionar una Sonda' })
        this.selectedCamara.sondas.forEach(sonda => {
          this.optionsSonda.push({ value: sonda, text: sonda.nombre })
        })
      } else {
        this.optionsSonda = []
        this.selectedSonda = null
      }
    },
    selectedSonda: function (val) {
      if (this.selectedSonda !== null) {
        // console.log(this.selectedSonda)
      }
    }
  },
  mounted () {
    // console.log('alertas')
    this.getSitios().then((res) => {
      console.log(this.sitios)
      this.optionsSitio.push({ value: null, text: 'Seleccionar un Cliente' })
      this.optionsCamara.push({ value: null, text: 'Seleccionar una Camara' })
      this.optionsSonda.push({ value: null, text: 'Seleccionar una Sonda' })
      this.sitios.data.forEach(element => {
        console.log(element)
        this.optionsSitio.push({ value: element, text: element.nombre })
      })
    })
    this.creador = this.user
  },
  methods: {
    ...mapActions('Alerta', [
      'getAlertas', 'insertAlerta'
    ]),
    ...mapActions('Sitio', [
      'getSitios'
    ]),
    crear () {
      if (this.selectedSonda === null) {
        const toast = {}
        toast.body = 'Debe seleccionar una sonda'
        toast.title = 'Error'
        toast.variant = 'danger'
        this.makeToast(toast)
        return false
      }
      if (this.correo === '') {
        const toast = {}
        toast.body = 'Debe ingresar un correo'
        toast.title = 'Error'
        toast.variant = 'danger'
        this.makeToast(toast)
        return false
      }
      const alerta = {
        id_sonda: this.selectedSonda.id_sonda,
        creador: this.creador,
        nombre_sitio: this.selectedSitio.nombre,
        nombre_camara: this.selectedCamara.nombre,
        nombre_sonda: this.selectedSonda.nombre,
        temperatura_minima: this.temperaturaMinima,
        temperatura_maxima: this.temperaturaMaxima,
        correo: this.correo,
        activa: this.activa
      }
      console.log(alerta)
      this.insertAlerta(alerta).then((res) => {
        console.log('inserta alerta con exito')
        this.$emit('insertAlerta', alerta)
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    }
  },
  computed: {
    ...mapState('Alerta', ['alertas']),
    ...mapState(['user']),
    ...mapState('Sitio', ['sitios'])
  }
}
