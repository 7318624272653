import { mapActions, mapState } from 'vuex'
import LineChart from '@/components/Layout/Common/Graficos/LineChart/LineChart.vue'
import VueHtml2pdf from 'vue-html2pdf'
import AlertaTemperaturaView from '@/components/Layout/Menu/Alertas/Alerta_temperatura/AlertaTemperaturaView.vue'
import FiltroTemperaturaView from '@/components/Layout/Menu/Temperatura/Filtro_temperatura/FiltroTemperaturaView.vue'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'TemperaturaView',
  components: {
    LineChart,
    VueHtml2pdf,
    AlertaTemperaturaView,
    FiltroTemperaturaView
  },
  props: {
    sonda: null,
    sitio: null,
    camara: null
  },
  data: function () {
    return {
      items: [],
      render: null,
      Labels: [],
      Datasets: [{
        label: 'Data One',
        fill: false,
        radius: 0,
        backgroundColor: '#f87979',
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        borderColor: 'rgb(0, 0, 0)',
        // Data to be represented on y-axis
        data: []
      }],
      temperaturaMaxima: 25,
      temperaturaMinima: 0,
      styleMax: {
        backgroundColor: 'red'
      },
      styleMin: {
        backgroundColor: 'blue'
      },
      infoAlerta: {
        id: this.sonda.id_sonda,
        title: '',
        content: ''
      },
      show: false,
      sondaID: [],
      filtroFecha: {}
    }
  },
  watch: {
    filtroFecha: function (val) {
      console.log(this.filtroFecha)
      this.Datasets = []
      this.Labels = []
      this.Datasets = [{
        label: 'Data One',
        fill: false,
        radius: 0,
        backgroundColor: '#f87979',
        pointBackgroundColor: 'white',
        borderWidth: 1,
        pointBorderColor: '#249EBF',
        borderColor: 'rgb(0, 0, 0)',
        data: []
      }]
      const dataFecha = {
        id_sonda: this.sonda.id_sonda,
        periodos: {
          desde: this.filtroFecha.desde,
          hasta: this.filtroFecha.hasta
        }
      }
      this.buscarTemperaturas(dataFecha)
    },
    sondaID: function (val) {
      if (this.sondaID.sondaID !== []) {
        setTimeout(() => {
          console.log('esperando 2 segundo')
          this.getByIdSonda(this.sondaID.sondaID).then((res) => {
            res.data.forEach(alerta => {
              console.log(alerta.temperatura_maxima)
              console.log(alerta.temperatura_minima)
              this.Datasets.push({
                label: 'Temperatura Maxima', // Este es el nombre en la leyenda
                fill: false, // Esto hace que no se rellene el area debajo de la línea
                radius: 0, // Esto hace que no se vean puntos para cada dato
                borderColor: 'rgb(255, 0, 0)',
                pointHitRadius: 0,
                data: this.Datasets[0].data.map(label => (alerta.temperatura_maxima))
              })
              this.Datasets.push({
                label: 'Temperatura Minima', // Este es el nombre en la leyenda
                fill: false, // Esto hace que no se rellene el area debajo de la línea
                radius: 0, // Esto hace que no se vean puntos para cada dato
                borderColor: 'rgb(0, 73, 255)',
                pointHitRadius: 0,
                data: this.Datasets[0].data.map(label => (alerta.temperatura_minima))
              })
              // this.render = alerta.id
            })
            // console.log(this.Datasets)
            this.render = this.Datasets
          })
        }, 2000)
      }
    }
  },
  mounted () {
    const date = new Date()
    const fechaActualHasta = `${
      date.getFullYear().toString().padStart(4, '0')}-${
        (date.getMonth() + 1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')} ${
            date.getHours().toString()}:${
              date.getMinutes().toString()}:${
                date.getSeconds().toString()}`
    const datePast = new Date()
    let miliseconds = date.getMilliseconds()
    const milisecondsDay = 24 * 60 * 60 * 1000
    miliseconds = miliseconds - milisecondsDay
    datePast.setMilliseconds(miliseconds)
    const fechaActualDesde = `${
      datePast.getFullYear().toString().padStart(4, '0')}-${
        (datePast.getMonth() + 1).toString().padStart(2, '0')}-${
          datePast.getDate().toString().padStart(2, '0')} ${
            datePast.getHours().toString()}:${
              datePast.getMinutes().toString()}:${
                datePast.getSeconds().toString()}`
    // console.log(fechaActualDesde)
    // console.log(fechaActualHasta)
    const data = {
      id_sonda: this.sonda.id_sonda,
      periodos: {
        desde: fechaActualDesde,
        hasta: fechaActualHasta
      }
    }
    this.buscarTemperaturas(data)
    // this.show = true
    // console.log(data.id_sonda)
    // this.getemperaturas(data).then((res) => {
    //   this.items = []
    //   this.items = this.temperaturas.data
    //   this.Datasets[0].label = this.sonda.nombre
    //   for (const temperatura in this.items) {
    //     const fecha = this.items[temperatura].fecha_server.replace('T', ' ').substring(0, 19)
    //     this.Labels.push(fecha)
    //     this.Datasets[0].data.push(this.items[temperatura].temperatura)
    //   }
    //   this.render = this.Datasets[0].data
    //   this.show = false
    //   this.sondaID = this.sonda.id_sonda
    // })
  },
  methods: {
    ...mapActions('Temperatura', [
      'getemperaturas'
    ]),
    buscarTemperaturas (data) {
      this.show = true
      console.log(data.id_sonda)
      this.getemperaturas(data).then((res) => {
        this.items = []
        this.items = this.temperaturas.data
        console.log(this.items)
        this.Datasets[0].label = this.sonda.nombre
        for (const temperatura in this.items) {
          const fecha = this.items[temperatura].fecha_server.replace('T', ' ').substring(0, 19)
          this.Labels.push(fecha)
          this.Datasets[0].data.push(this.items[temperatura].temperatura)
        }
        this.render = this.Datasets[0].data
        this.show = false
        // this.sondaID detecta la sonda para ir a buscar sus alarmas
        const dataSonda = {
          sondaID: this.sonda.id_sonda,
          Datasets: this.Datasets
        }
        this.sondaID = dataSonda
      })
    },
    graficar () {
      // console.log(this.temperaturaMaxima)
      // console.log(this.temperaturaMinima)
      this.Datasets[0].data = []
      this.Labels = []
      for (const temperatura in this.items) {
        if (this.items[temperatura].temperatura > this.temperaturaMinima && this.items[temperatura].temperatura < this.temperaturaMaxima) {
          const fecha = this.items[temperatura].fecha_server.replace('T', ' ').substring(0, 19)
          this.Labels.push(fecha)
          this.Datasets[0].data.push(this.items[temperatura].temperatura)
        }
      }
      this.render = this.Datasets[0].data
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    AlertaModal (item, index, button) {
      // this.datosCliete(item)
      this.infoAlerta.title = 'Alerta'
      this.infoAlerta.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoAlerta.id, button)
    },
    resetAlertaModal () {
      this.infoAlerta.title = ''
      this.infoAlerta.content = ''
    },
    hideAlertaModal () {
      this.$refs[this.infoAlerta.id].hide()
    },
    async getByIdSonda (idSonda) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/sodexo/alertas/getByIdSonda?id_sonda=${idSonda}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getByIdSonda')
          // console.log(response.data)
          return response
        } else {
          console.log('por else getByIdSonda')
        }
        return response
      } catch (err) {
        console.error(err)
        console.log('por catch getByIdSonda')
        // router.push({ path: '/' })
        return 'error getByIdSonda'
      }
    }
  },
  computed: {
    ...mapState('Temperatura', ['temperaturas'])
  }
}
