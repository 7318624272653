import { mapActions, mapState } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'
import TemperaturaView from '@/components/Layout/Menu/Modulos/Panel_control/KPI/Temperatura/TemperaturaView.vue'
import { slider, slideritem } from 'vue-concise-slider'

export default {
  name: 'PanelControlView',
  components: {
    TemperaturaView,
    Carousel,
    Slide,
    slider,
    slideritem
  },
  props: {
  },
  data: function () {
    return {
      show: false,
      slide: 0,
      sliding: null,
      options: {
        currentPage: 0,
        widthScalingRatio: '0.1'
      },
      someList: [
        {
          html: 'slide1',
          style: {
            background: '#1bbc9b'
          }
        },
        {
          html: 'slide2',
          style: {
            background: '#4bbfc3'
          }
        },
        {
          html: 'slide3',
          style: {
            background: '#7baabe'
          }
        }
      ]
    }
  },
  watch: {
  },
  mounted () {
    this.cargarKpiTemperaturas()
  },
  methods: {
    ...mapActions('KPI', [
      'getSKpiTemperaturas'
    ]),
    cargarKpiTemperaturas () {
      this.show = true
      this.getSKpiTemperaturas().then((res) => {
        this.show = false
        // console.log(res)
      })
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    onShown () {
      // Focus the cancel button when the overlay is showing
      this.$refs.cancel.focus()
    },
    onHidden () {
      // Focus the show button when the overlay is removed
      this.$refs.show.focus()
    }
  },
  computed: {
    ...mapState('KPI', ['temperaturas'])
  }
}
