// import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'ConfiguracionView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    // ...mapActions('AdministradorFlota', [
    //   'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    // ])
  },
  computed: {
    // ...mapState('AdministradorFlota', ['transportes'])
  }
}
