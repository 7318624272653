import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
import Sitio from '@/modules/Sitio.js'
import Temperatura from '@/modules/Temperatura.js'
import KPI from '@/modules/KPI.js'
import Alerta from '@/modules/Alerta.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // host: 'http://localhost:8090',
    // hostGateway: 'http://localhost:8090',
    host: 'https://app.sodexo.dmetrix.cl',
    hostGateway: 'https://app.sodexo.dmetrix.cl',
    user: '',
    password: '',
    token: '',
    imagenUsuario: '',
    perfilUsuario: {},
    usuarios: []
  },
  getters: {
  },
  mutations: {
    set_token (state, autorizacion) {
      state.token = autorizacion.token
      state.user = autorizacion.user
      state.password = autorizacion.password
    },
    set_accion_imagenUsuario (state, imagenUsuario) {
      state.imagenUsuario = imagenUsuario
    },
    set_accion_perfilUsuario (state, perfilUsuario) {
      state.perfilUsuario = perfilUsuario
      state.imagenUsuario = perfilUsuario.imagen_usuario_base64
    },
    set_accion_usuarios (state, usuarios) {
      state.usuarios = usuarios
    }
  },
  actions: {
    async GetToken ({ commit, dispatch, state }, credenciales) {
      const autorizacion = {}
      try {
        const response = await axios.post(`${store.state.hostGateway}/api/login/auth/iniciarSesion`, credenciales)
        // const response = await axios.post(`http://${store.state.host}/api/auth/iniciarSesion`, credenciales)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff')
          autorizacion.token = response.data.tokenDeAcceso
          autorizacion.user = credenciales.usernameOrEmail
          autorizacion.password = credenciales.password
          commit('set_token', autorizacion)
          dispatch('getPerfilUsuario', credenciales.usernameOrEmail).then((res) => {
            console.log('cargado GetRol set_accion_perfilUsuario')
            router.push({ path: 'menu' })
          })
          // window.location.href = 'http://www.google.com'
          // router.push({ path: 'menu' })
          return true
        } else {
          autorizacion.user = ''
          autorizacion.password = ''
          autorizacion.token = 'clave o usuario invalido'
          commit('set_token', autorizacion)
          console.log('por else')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch')
        autorizacion.user = ''
        autorizacion.password = ''
        autorizacion.token = 'clave o usuario invalido'
        commit('set_token', autorizacion)
        return false
      }
    },
    async getImagenUsuario ({ commit, dispatch, state }, usuario) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.hostGateway}/api/login/files/getImagenUsuario?user=${usuario}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getImagenUsuario')
          // console.log(response.data)
          commit('set_accion_imagenUsuario', response.data)
          return true
        } else {
          console.log('por else getImagenUsuario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getImagenUsuario')
        // window.location.href = '/'
        router.push({ path: '/' })
        return false
      }
    },
    async getPerfilUsuario ({ commit, dispatch, state }, usuario) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.hostGateway}/api/login/auth/getPerfil?emailOrUser=${usuario}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getPerfilUsuario')
          // console.log(response.data)
          commit('set_accion_perfilUsuario', response.data)
          return true
        } else {
          console.log('por else getPerfilUsuario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getPerfilUsuario')
        router.push({ path: '/' })
        return false
      }
    },
    async getUsuarios ({ commit, dispatch, state }, groupId) {
      try {
        const response = await axios({
          method: 'get',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.hostGateway}/api/login/auth/getUsuariosGrupo?group_id=${groupId}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getUsuarios')
          // console.log(response.data)
          commit('set_accion_usuarios', response.data)
          return true
        } else {
          console.log('por else getUsuarios')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getUsuarios')
        router.push({ path: '/' })
        return false
      }
    },
    async setUsuario ({ commit, dispatch, state }, usuario) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.hostGateway}/api/login/auth/registrar`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: usuario
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setUsuario')
          // console.log(response.data)
          commit('set_accion_usuarios', response.data)
          return true
        } else {
          console.log('por else setUsuario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setUsuario')
        // commit('set_accion_usuarios', response.data)
        // router.push({ path: '/' })
        return false
      }
    },
    async deleteUsuario ({ commit, dispatch, state }, username) {
      try {
        const response = await axios({
          method: 'delete',
          url: `http://${store.state.hostGateway}/api/login/auth/delete?username=${username}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) {
          console.log('success stuff api deleteUsuario')
          commit('set_accion_usuarios', response.data)
          return true
        } else {
          console.log('por else deleteUsuario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch deleteUsuario')
        return false
      }
    },
    async updateUsuario ({ commit, dispatch, state }, usuario) {
      try {
        const response = await axios({
          method: 'put',
          url: `${store.state.hostGateway}/api/login/auth/update`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: usuario
        })
        if (response.status === 200) {
          console.log('success stuff api updateUsuario')
          commit('set_accion_usuarios', response.data)
          return true
        } else {
          console.log('por else updateUsuario')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch updateUsuario')
        return false
      }
    }
  },
  modules: {
    Sitio,
    Temperatura,
    KPI,
    Alerta
  }
})
